import React from "react";
import { Link, NavLink } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { HiOutlineChevronRight } from "react-icons/hi2";
import { PiCertificate, PiNotebookBold, PiUsersThree } from "react-icons/pi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { TbCategory } from "react-icons/tb";

const SidePanelAdmin = (props) => {

    return (
        <>
            <div className="side-panel">
                <div className="logo position-relative"><Link to="/admin/dashboard"><img src={require("../../images/logo.svg").default} alt="" /></Link><span className="page-name">Admin</span></div>

                {/* <div className="fav-tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Nav variant="nav">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Favorites</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Recently</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <ul className="tab-link">
                                    <li><Link>Overview</Link></li>
                                    <li><Link>Projects</Link></li>
                                </ul>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <ul className="tab-link">
                                    <li><Link>Overview</Link></li>
                                    <li><Link>Projects</Link></li>
                                </ul>
                            </Tab.Pane>

                        </Tab.Content>
                    </Tab.Container>
                </div> */}

                <div className="dash-link pt-2">
                    {/* <h6>Dashboard</h6> */}
                    <ul className="pt-0">
                        <li><NavLink to="/admin/dashboard"><HiOutlineChevronRight /> <img src={require("../../images/icon/agent.svg").default} alt="" /> Dashboard</NavLink></li>
                        <li><NavLink to="/admin-manger-user" className={props.type === "creator" ? "active" : ""}><HiOutlineChevronRight /> <PiUsersThree fontSize={20} /> Manage Users</NavLink></li>
                        <li><NavLink to="/admin/all-task" className={props.type === "url" ? "active" : ""}><HiOutlineChevronRight /> <PiNotebookBold fontSize={20} /> Manage Tasks</NavLink></li>
                        <li><NavLink to="/admin/nav/payout"><HiOutlineChevronRight /> <RiMoneyDollarCircleLine fontSize={20} /> Manage Payouts</NavLink></li>
                        <li><NavLink to="/admin/task-category"><HiOutlineChevronRight /> <TbCategory fontSize={20}
                        alt="" /> Task Category</NavLink></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SidePanelAdmin;