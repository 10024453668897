import axios from "axios";
import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";
import { baseURL } from "../../Global/Global";

export const FetchTaskTaskerData = (setData,setLoader,loader) => (dispatch, getState) => {

    commonAxios("fetch-task-list", {}, dispatch,getState().auth.token)
        .then((res) => {
            if (res.status) {
              if(setData){
                setData(res.data)
              }
                
             
                 dispatch({ type:'Fetch_Task_detail', payload: res.data });
               } 
               else {
                dispatch(setAlert(res.msg, "danger"));
            }
            if(loader){
                setLoader({
                    ...loader,
                    fetch:false
                })
            }
           
        }).catch((err) => {
         console.log(err)
        
        })
}

export const FetchTaskTaskerSubmissionData = (setSubmissionData,setLoader) => (dispatch, getState) => {

    commonAxios("fetch-task-submission", {}, dispatch,getState().auth.token)
        .then((res) => {
             
            if (res.status) {
               
                //  dispatch({ type:'Fetch_Task_detail', payload: res.data });
                setSubmissionData(res.data)
               } else {
                dispatch(setAlert(res.msg, "danger"));
            }
           setLoader(false)
        }).catch((err) => {
         console.log(err)
        //  setLoader(false)
        })
}
export const TaskTaskerDetails = (fromdata,setTaskDetails,setLoader) => (dispatch, getState) => {

    commonAxios("fetch-task-details", fromdata, dispatch,getState().auth.token)
        .then((res) => {

            if (res.status) {
               
                setLoader({
                    create:false
                })
                setTaskDetails(res.data[0])
               } else {
                dispatch(setAlert(res.msg, "danger"));
                setLoader({
                    create:false
                })
            }
           
        }).catch((err) => {
         console.log(err)
           
        })
}
export const BookMarkTaskTasker = (data, FetchData, setLoader) => (dispatch, getState) => {

    commonAxios("add_saved_task",data, dispatch,getState().auth.token)
        .then((res) => {

            if (res.status) {
              
                     dispatch(setAlert(res.msg,"success"))
                 dispatch({ type:'Fetch_Task_detail', payload: res.data });
                 FetchData()
               } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
         console.log(err)
           
        })
}
export const submitTaskModelAction = (data, setLoader, handleShow, handleClose) => (dispatch, getState) => {
    let config = {
        headers: {
            'Authorization': getState().auth.token,
            "Content-Type": "multipart/form-data",
        }
    }
    commonAxios("task-submission", data, dispatch, getState().auth.token, config)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
                handleShow()
                handleClose()
                setLoader(false)
            } else {
                dispatch(setAlert(res.msg, "danger"));
                setLoader(false)
                handleClose()

            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}
export const LinkSubmissionTasker= (data,setLoader,handleShow,handleClose) => (dispatch, getState) => {

    commonAxios("task-submission",data, dispatch,getState().auth.token)
        .then((res) => {

            if (res.status) {
               
                     dispatch(setAlert(res.msg,"success"))
                     handleShow()
                     handleClose()
                setLoader(false)
               } else {
                dispatch(setAlert(res.msg, "danger"));
                setLoader(false)
                handleClose()
                
            }
            setLoader(false)
        }).catch((err) => {
         console.log(err)
         setLoader(false)
       
        })
}
export const ApplicationSubmit= (data,setLoader,handleShow,handleClose) => (dispatch, getState) => {

    commonAxios("task-submission",data, dispatch,getState().auth.token)
        .then((res) => {

            if (res.status) {
             
                     dispatch(setAlert(res.msg,"success"))
                     handleShow()
                     handleClose()
                setLoader(false)
               } else {
                dispatch(setAlert(res.msg, "danger"));
                setLoader(false)
                handleClose()
            }
            setLoader(false)
        }).catch((err) => {
         console.log(err)
         setLoader(false)
        })
}
export const TaskerReviewSubmit= (data,setLoader,handleClose) => (dispatch, getState) => {

    commonAxios("submit-creator-review",data, dispatch,getState().auth.token)
        .then((res) => {

            if (res.status) {
             
                     dispatch(setAlert(res.msg,"success"))
                    
                     handleClose()
                setLoader(false)
               } else {
                dispatch(setAlert(res.msg, "danger"));
                setLoader(false)
                handleClose()
            }
            setLoader(false)
        }).catch((err) => {
         console.log(err)
         setLoader(false)
        })
}


// export const FetchUploadFiles= (data,setLoader,setPdfData,loader) => (dispatch, getState) => {
//     setLoader({
//         ...loader,
//         "fetch":true
//     })
//     commonAxios("",data, dispatch,getState().auth.token)
//         .then((res) => {

//             if (res.status) {
//               console.log("fetchfloder",res)
//               setPdfData(res.data)
//                     //  dispatch(setAlert(res.msg,"success"))
//                 //  dispatch({ type:'Fetch_Task_detail', payload: res.data });
//                 setLoader({
//                     ...loader,
//                     "fetch":false
//                 })
//                } else {
//                 dispatch(setAlert(res.msg, "danger"));
               
//                 setLoader({
//                     ...loader,
//                     "fetch":false
//                 })
//             }
            
//             setLoader({
//                 ...loader,
//                 "fetch":false
//             })
//         }).catch((err) => {
//          console.log(err)
        
//          setLoader({
//             ...loader,
//             "fetch":false
//         })
//         })
// }