import React from "react";
import { Link, NavLink } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { HiOutlineChevronRight } from "react-icons/hi2";
import { PiCertificate, PiNotebookBold } from "react-icons/pi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { HiOutlineHandRaised } from "react-icons/hi2";
import { VscReferences } from "react-icons/vsc";
import { FaQuestion } from "react-icons/fa6";

const SidePanel = (props) => {

    return (
        <>
            <div className="side-panel">
                <div className="logo position-relative"><Link to="/dashboard"><img src={require("../../images/logo.svg").default} alt="" /></Link><span className="page-name">Tasker</span></div>
                {/* <div className="fav-tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Nav variant="nav">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Favorites</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Recently</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <ul className="tab-link">
                                    <li><Link>Overview</Link></li>
                                    <li><Link>Projects</Link></li>
                                </ul>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <ul className="tab-link">
                                    <li><Link>Overview</Link></li>
                                    <li><Link>Projects</Link></li>
                                </ul>
                            </Tab.Pane>

                        </Tab.Content>
                    </Tab.Container>
                </div> */}

                <div className="dash-link pt-1">
                    {/* <h6>Dashboard</h6> */}
                    <ul>
                        <li><NavLink to="/dashboard"><HiOutlineChevronRight /> <img src={require("../../images/icon/agent.svg").default} alt="" /> Dashboard</NavLink></li>
                        <li><NavLink to="/tasks" className={props.type === "url" ? "active" : ""}><HiOutlineChevronRight /> <PiNotebookBold fontSize={20} /> Tasks</NavLink></li>
                        {/* <li><NavLink to="/achievements"><HiOutlineChevronRight /> <PiCertificate fontSize={20} /> Achievements</NavLink></li> */}
                        <li><NavLink to="/earnings"><HiOutlineChevronRight /> <RiMoneyDollarCircleLine fontSize={20} /> Earnings</NavLink></li>
                        <li><NavLink to="/raised-query"><HiOutlineChevronRight /> <HiOutlineHandRaised fontSize={18} /> Raised Query</NavLink></li>
                        <li><NavLink to="/referal"><HiOutlineChevronRight /> <VscReferences fontSize={18} />Referal</NavLink></li>
                        <li><NavLink to="/faq"><HiOutlineChevronRight /> <FaQuestion fontSize={18} />FAQ</NavLink></li>
                        {/* <li><NavLink to="/profile-settings"><HiOutlineChevronRight /> <img src={require("../../images/icon/IconSet.svg").default} alt="" /> Settings</NavLink></li> */}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SidePanel;