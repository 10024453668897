import React, { useEffect, useState } from "react";

import { IoCalendarClearOutline } from "react-icons/io5";
import { FiDownload, FiChevronLeft, FiChevronRight } from "react-icons/fi";

import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";
import ProfileInfo from "./ProfileInfo";

function PaymentMethodsTabs(props) {

  const [bankData, setBankData] = useState([])
  const [upiData, setUpiData] = useState([])
  useEffect(() => {
    if (props.data) {
      if (props.data.bank_details) {
        setBankData(props.data.bank_details)
      }
      if (props.data.upi_details) {
        setUpiData(props.data.upi_details)
      }

    }
  }, [props.data])
  return (
    <>



      <div className="account-block mt-4">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <h6 className="pb-3 pb-sm-0"> Payment Methods</h6>
          <ul className="payment-tab">
            <li className="active">Bank Details</li>

            <li>UPI ID</li>
          </ul>
        </div>

        <div className="payment-box">
          <div className="row">
            {
              bankData.length > 0 ? bankData.map((curElem, i) => {

                return (
                  <div className="col-sm-4 mt-4" key={i}>
                    <div className="payment-box-single">
                      <div className="row align-items-center">
                        <div className="col d-flex align-items-center gap-2">
                          <h6>{curElem.bank_name}</h6>
                          <span className="table-stat active">{curElem.is_primary === "1" ? "Primary" : ""}</span>
                        </div>
                        {/* <div className="col-auto">
                    <button className="theme-btn light">Edit</button>
                  </div> */}
                      </div>
                      <h6 className="pt-3">{curElem.account_no}</h6>
                      <h6 className="pt-3">{curElem.account_holder_name}</h6>
                      <h6 className="pt-3">{curElem.ifsc_code}</h6>
                      <h6 className="pt-3">{curElem.branch_name}</h6>

                      {/* <div className="row align-items-center justify-content-between mt-4">
                  <div className="col-auto">
                    <p>Exp 06/25</p>
                  </div>
                  <div className="col-auto"><img src={require("../../../images/icon/visa.svg").default} alt="" /></div>
                </div> */}
                    </div>
                  </div>

                )
              }) : ""
            }


            <div className="col-sm-4 mt-4">
              <div className="payment-box-single">
                <div className="row align-items-center">
                  <div className="col d-flex align-items-center gap-2">
                    <h6>{upiData?.upi}</h6>
                  </div>

                </div>
                <h6 className="pt-3">{upiData?.upi_name}</h6>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentMethodsTabs;