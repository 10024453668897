import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpider, FaSpinner } from "react-icons/fa";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import OTPInput, { ResendOTP } from "otp-input-react";

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { MdOutlineEmail } from "react-icons/md";
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { UpdateMobileEmailVerify } from '../../../Redux/Actions/AuthActions';

const AdminUpdateOtp = (props) => {


    const [OTP, setOTP] = useState("");
    const navigation = useNavigate()
    const [loader, setLoader] = useState(false)

    const [show, setShow] = useState(false);
    const dispatch = useDispatch()
    const handleverify = () => {

        setLoader(true)
        if (OTP.length < 4) {

            setLoader(false)
            dispatch(setAlert("Please enter OTP", "danger"))
        }
        else {
            setLoader(true)
            if (props.modelType?.data?.type === "mobile") {
                let Data = {
                    "mobile": props.modelType?.data?.mobile,
                    "otp": OTP,
                    type: props.modelType?.data?.type
                }
                setOTP("")
                dispatch(UpdateMobileEmailVerify(Data, setLoader, props.handleClose))
            } else {
                let Data = {
                    "email": props.modelType?.data?.email,
                    "otp": OTP,
                    type: props.modelType?.data?.type
                }
                setOTP("")
                dispatch(UpdateMobileEmailVerify(Data, setLoader, props.handleClose))
            }





        }

    }
    return (
        <>
            <Modal className="theme-modal forgotpass-modal link_submit_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>

                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center modal-btn justify-content-between'>

                        <h3 className="m-auto" style={{ fontSize: "30px" }}>Verify Your {props.modelType?.data?.type === "email" ? "Email" : "Mobile"}</h3>
                        <span onClick={props.handleClose}><RxCross1 /></span>

                    </div>

                    <div className="modal-card mt-4 ">
                        <div className='otp-wrapper d-flex flex-column align-items-center mb-4'>
                            <MdOutlineEmail style={{ fontSize: "50px" }} />
                            <h3>Check your {props.modelType?.data?.type === "email" ? "email" : "mobile"} </h3>
                            <p style={{ fontSize: "14px", textAlign: "center" }}>A verification code has been sent to your {props.modelType?.data?.type === "email" ? "email address" : "mobile number"} . Please enter the code received in your {props.modelType?.data?.type === "email" ? "email" : "mobile"}

                            </p>
                            <h5>{props.modelType?.data?.type === "email" ? props.modelType?.data?.email : props.modelType?.data?.mobile}</h5>

                        </div>
                        <div className="d-flex align-items-center justify-content-center m-auto modal-otp flex-column text-center" style={{ width: "50%" }}>
                            <div>

                                <OTPInput className="p-0" value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} secure />

                            </div>
                        </div>

                        <div className="link_btn mt-2 text-end">
                            <button className='theme-btn saveBtn' type="submit" style={{ padding: "12px 24px" }} onClick={handleverify}>
                                Verify  {loader ? <FaSpinner style={{ fontSize: "18px" }} className="spin ms-1" /> : null}</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >

        </>
    )
}

export default AdminUpdateOtp
