import React, { useState, useEffect } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel'
import { LuDownload } from 'react-icons/lu'
import event from '../../../images/icon/events.svg'
import { Dropdown } from 'react-bootstrap'
import { IoSearchOutline } from 'react-icons/io5'
import Pagination from '../../Common/Pagination'
import { FaSpinner } from 'react-icons/fa'

const AdminPayoutHistory = (props) => {
    const [searchInput, setSearchInput] = useState('')

    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [data, setData] = useState([])

    const handlesort = () => {

        const sortedData = [...filteredData].sort((a, b) => {
            const dateA = new Date(a.withdraw_date);
            const dateB = new Date(b.withdraw_date);

            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    useEffect(() => {

        if (data) {
            if (searchInput) {

                const newFilteredData = data.filter(val =>

                    val.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    String(val.amount || '').includes(searchInput.toLowerCase()) ||
                    String(val.id || '').includes(searchInput.toLowerCase())
                );




                setFilteredData(newFilteredData);
            } else {
                setFilteredData(data);
            }
        }
    }, [searchInput, data]);

    useEffect(() => {
        if (props.data) {
            setData(props.data)
        } else {
            setData([])
        }
    }, [props.data])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 15
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);

    return (
        <>
            {
                props.loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :

                    <ScrollPanel className="custombar2 earnings_payouts content-area">

                        <div className="content-area-in p-0 " style={{ marginTop: "-10px" }}>

                            <div className="account-block bg-transparent ">
                                <div className="table-bar">
                                    <div className="table-bar-left">
                                        <div className="table-bar-icon">
                                            <div className="table-nav">
                                                <div className="profile-menu">

                                                </div>
                                                {/* <div className="profile-menu">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="successN" className="drop-btn">
                                                    <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                    <li className="drop-list">
                                                        <span className="custom-check">
                                                            <input
                                                                type="checkbox"
                                                                name="agentType"
                                                            />
                                                            <span className="checkMark"></span>
                                                        </span>
                                                        dolor sit amet
                                                    </li>
                                                    <li className="drop-list">
                                                        <span className="custom-check">
                                                            <input
                                                                type="checkbox"
                                                                name="agentType"
                                                            />
                                                            <span className="checkMark"></span>
                                                        </span>
                                                        dolor sit amet.
                                                    </li>


                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> */}
                                            </div>


                                            <ul>
                                                <li title="Sort by Date" onClick={handlesort}>
                                                    <img src={require("../../../images/icon/sort.svg").default} alt="" />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="table-bar-right">
                                        <div className="header-search">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                name="searchInput"
                                                value={searchInput}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                            />
                                            <span className="left-icon"><IoSearchOutline
                                            /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive earnings_table mt-3">
                                    <table className="table theme-table">
                                        <thead>
                                            <tr>
                                                {/* <th>
                                            <span className="custom-check">
                                                <input
                                                    type="checkbox"
                                                />
                                                <span className="checkMark"></span>
                                            </span>
                                        </th> */}
                                                <th>Tansactoion ID</th>

                                                <th>Description</th>
                                                <th>Date</th>
                                                <th>Payout Amount</th>
                                                <th>Remaining Balance</th>
                                                <th className='text-center'>Status</th>
                                                <th className='text-center'> Paid Status</th>



                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentTodo.length > 0 ? currentTodo.map((curElem, i) => {
                                                const bankDetails = JSON.parse(curElem.payment_detail || null)



                                                return (
                                                    <tr key={i}>
                                                        {/* <td data-label="">
                                            <span className="custom-check">
                                                <input
                                                    type="checkbox"
                                                />
                                                <span className="checkMark"></span>
                                            </span>
                                        </td> */}

                                                        <td data-label="Transaction ID">{curElem.id}</td>
                                                        <td data-label="Description">Bank Transfer for ₹{curElem.amount} form {curElem?.payment_mode}</td>
                                                        <td data-label="Date"><img src={event} alt="" />{curElem.created}</td>
                                                        <td data-label="Payout Amount">₹{curElem.amount}</td>
                                                        <td data-label="Remaining Balance">₹{curElem.balance}</td>
                                                        <td data-label="Status">
                                                            {curElem.status === '2' ? <div className='earn_status complete m-auto' style={{width:"fit-content", background: "#d4edda", color: "#155724" }}>Approved</div> : curElem.status === '3' ? <div className='earn_status m-auto' style={{width:"fit-content", background: "#f8d7da", color: "#721c24" }}>Rejected</div> : <div className='earn_status m-auto' style={{width:"fit-content", background: "rgb(255, 191, 0)" }}>Pending</div>}
                                                        </td>

                                                        <td data-label="Paid Status">
                                                            {curElem.is_payed === '1' ? <div className='earn_status complete m-auto'>Paid</div> : <div className='earn_status m-auto' style={{width:"fit-content", background: "#fff3cd", color: "#856404" }}>UnPaid</div>}
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <td className='text-center py-3' colSpan={7}>{data.length === 0 ? "No tasks Found" : ""}</td>}


                                        </tbody>
                                    </table>
                                    <Pagination

                                        listData={filteredData}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        currentTodo={currentTodo}
                                        listArr={data}
                                        loader={props.loader.fetch}
                                    />
                                </div>
                            </div>


                        </div>

                    </ScrollPanel >
            }
        </>
    )
}

export default AdminPayoutHistory
