import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpider, FaSpinner } from "react-icons/fa";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import OTPInput, { ResendOTP } from "otp-input-react";
import { setAlert } from '../../Redux/Actions/AlertActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import varifImg from '../../images/social icon/EnvelopeSimple.svg'
import lessThen from '../../images/icon/lessthen.svg'
import { MdOutlineEmail } from "react-icons/md";
import SetPasswordModal from './SetPasswordModel';
import { ForgetOtpVerify, ForgetReSendOtpAction } from '../../Redux/Actions/AuthActions';
// import { FaSpider, FaSpinner } from "react-icons/fa";

const OtpModelForget = (props) => {

    const [OTP, setOTP] = useState("");
    const navigation = useNavigate()
    const [loader, setLoader] = useState(false)

    const [show, setShow] = useState(false);
    const dispatch = useDispatch()
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleverify = () => {

        setLoader(true)
        if (OTP.length < 4) {

            setLoader(false)
            dispatch(setAlert("Please enter OTP", "danger"))
        }
        else {
            let Data = {
                "email": props.email,
                "otp": OTP,
                "hash": props.session_id
            }


            dispatch(ForgetOtpVerify(Data, setLoader, props.handleClose, handleShow))

        }

    }
    const handleResendOtp = () => {
        setIsDisabled(true)
        setTimeout(() => {
            setIsDisabled(false);
        }, 20000)
        let fromData = {

            "hash": props?.session_id
        }

        dispatch(ForgetReSendOtpAction(fromData))

    }
    return (
        <>
            <Modal className="theme-modal forgotpass-modal link_submit_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>

                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center modal-btn justify-content-between'>
                        {/* <span><MdOutlineArrowBackIos /></span> */}
                        <h3 className="m-auto" style={{ fontSize: "30px" }}>Verify Your Email</h3>
                        <span onClick={props.handleClose}><RxCross1 /></span>

                    </div>

                    <div className="modal-card mt-4 ">
                        <div className='otp-wrapper d-flex flex-column align-items-center mb-4'>
                            <MdOutlineEmail style={{ fontSize: "70px" }} />
                            <h3 style={{fontSize:"20px"}}>Check your email box</h3>
                            <p style={{ fontSize: "14px", textAlign: "center" }}>A verification code has been sent to your email address. Please enter the code received in your email

                            </p>
                            <h5 style={{fontSize:"18px"}}>{props.email}</h5>

                        </div>
                        <div className="d-flex align-items-center justify-content-center m-auto modal-otp flex-column text-center" style={{ width: "50%" }}>
                            <div>

                                <OTPInput className="p-0" value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} secure />
                                <div className="resend_code mt-3 text-center">
                                    <button type="submit" className=" btn m-auto" onClick={handleResendOtp} disabled={isDisabled}>Resend OTP </button>
                                </div>
                            </div>
                        </div>

                        <div className="link_btn mt-2 text-end">
                            <button className='theme-btn saveBtn' type="submit" style={{ padding: "12px 24px" }} onClick={handleverify}>
                                Verify  {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
            <SetPasswordModal show={show} handleClose={handleClose} hash={props.session_id} />
        </>
    )
}

export default OtpModelForget