import React, { useEffect, useState } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';

import SidePanel from "../../Common/SidePanel";
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import NotificationBar from "../../Common/NotificationBar";

import { IoCopyOutline, IoSearchOutline } from "react-icons/io5";
import { CiCalendar, CiMobile3 } from "react-icons/ci";
import TitleBar from '../../Common/TitleBar';
import { Dropdown } from 'primereact/dropdown';
import RaisedQueryModal from '../ModalComponents/RaisedQueryModal';
import { useDispatch } from 'react-redux';
import { closeQuery, fetchRaisedData } from '../../../Redux/Actions/EarningsAction';
import { FaSpinner } from 'react-icons/fa';
import Pagination from '../../Common/Pagination';
import { FaRupeeSign } from "react-icons/fa";
function RaisedQuery() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [id, setId] = useState("")
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [searchInput, setSearchInput] = useState('')
    const [loader, setLoader] = useState({
        fetch: false,
        close: false
    })
    const dispatch = useDispatch()
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };


    // const [selectedCity, setSelectedCity] = useState(null);
    // const cities = [
    //     { name: '10', code: '10' },
    //     { name: '20', code: '20' },
    //     { name: '30', code: '30' },
    //     { name: '40', code: '40' },
    //     { name: '50', code: '50' }
    // ];
    const fetchData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchRaisedData(setData, setLoader, loader))
    }
    useEffect(() => {

        if (data) {
            if (searchInput) {

                const newFilteredData = data.filter(val =>
                    String(val.amount || '').includes(searchInput.toLowerCase()) ||
                    String(val.id || '').includes(searchInput.toLowerCase())
                );

                setFilteredData(newFilteredData);
            } else {
                setFilteredData(data);
            }
        }
    }, [searchInput, data]);
    useEffect(() => {
        fetchData()
    }, [])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);
    const handleClosed = (pId) => {
        setId(pId)
        setLoader({
            ...loader,
            close: true
        })
        let formData = {
            "id": pId
        }

        dispatch(closeQuery(formData, setLoader, loader, fetchData))
    }

    return (
        <>
            <TitleBar title="RaisedQuery" />
            <div className="dashboard container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanel />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 ps-0 pe-0">
                                    <div className="dashboard-in">
                                        <DashboardHeader toggleSidebar={toggleClass} title="RaisedQuery" />
                                        <ScrollPanel className="custombar2 content-area">
                                            <div className="content-area-in" style={{ padding: "22px 35px" }}>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <h5>Raised Query list
                                                    </h5>
                                                    {/* <button className='theme-btn' onClick={handleShow}>Raised Query</button> */}
                                                </div>
                                                <div className="account-block refer-table mt-3">
                                                    <div className="row">

                                                        <h6 className='mb-3'>Refer User List
                                                        </h6>
                                                        {/* <div className="col">
                                                            <div className='d-flex align-items-center gap-2'>
                                                                <div className="" style={{ width: "fit-content" }}>
                                                                    <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
                                                                        placeholder="10" className="" />
                                                                </div>
                                                                <span>entries per page</span>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-auto">
                                                            <div className="table-bar-right">
                                                                <div className="header-search">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Search"
                                                                        name="searchInput"
                                                                        value={searchInput}
                                                                        onChange={(e) => setSearchInput(e.target.value)}
                                                                    />
                                                                    <span className="left-icon"><IoSearchOutline /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-responsive mt-2">
                                                            {

                                                                loader.fetch ? <div className='d-flex align-items-center justify-content-center'><FaSpinner className='spin ms-1' style={{ fontSize: "40px" }} /></div> :
                                                                    <table className="table theme-table">
                                                                        <tr>
                                                                            <th>Withdrawl Date
                                                                            </th>
                                                                            <th>Mode</th>
                                                                            <th>Amount</th>
                                                                            <th >Remark</th>
                                                                            <th >Query Status</th>
                                                                            <th >Action</th>
                                                                        </tr>


                                                                        {currentTodo.length > 0 ? currentTodo.map((curElem, i) => {
                                                                            return (
                                                                                < tr key={i}>
                                                                                    < td data-label="Withdrawl Date">{curElem.request_date}</td>
                                                                                    <td data-label="UPI">{curElem.payment_mode}</td>
                                                                                    <td data-label="Amount">
                                                                                        {curElem.amount} ₹</td>
                                                                                    <td data-label="Remark">{curElem.remark}</td>
                                                                                    <td data-label="Query Status" ><div className='badges' style={{ padding: "5px 20px", borderRadius: "50px", width: "fit-content", backgroundColor: curElem.is_solve === "1" ? "#def8ee" : " #fff3cd", color: curElem.is_solve === "1" ? "#4aa785" : "#856404" }}>{curElem.is_solve === "1" ? "solved" : "pending"}</div></td>
                                                                                    <td data-label="Action">
                                                                                        {
                                                                                            curElem.is_solve === "0" ? <div className='common-btn' style={{ margin: "unset", cursor: "pointer" }} onClick={() => handleClosed(curElem.id)}>
                                                                                                close query{curElem.id === id ? loader.close ? <FaSpinner className='spin ms-1' /> : null : ""}
                                                                                            </div>
                                                                                                : ""
                                                                                        }

                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                            : <tr><td className='text-center py-3' colSpan={7}>{data.length === 0 ? "No histroy found" : ""}</td></tr>
                                                                        }


                                                                    </table>
                                                            }
                                                        </div>
                                                    </div>

                                                    <Pagination
                                                        listData={filteredData}
                                                        pagination={pagination}
                                                        setPagination={setPagination}
                                                        currentTodo={currentTodo}
                                                        listArr={data}
                                                        loader={loader.fetch}
                                                    />
                                                </div>
                                            </div>
                                        </ScrollPanel>

                                        <div>
                                            <DashboardFooter />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 ps-0">
                            <RecommendTasks />
                        </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* <RaisedQueryModal
                show={show}
                handleClose={handleClose}
            /> */}


        </>
    )
}

export default RaisedQuery;
