import React, { useEffect, useRef, useState } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel';
import DashboardHeader from '../../Common/DashboardHeader';
import { Form } from 'react-bootstrap';
import calendar from '../../../images/icon/CalendarBlank.svg'
import { IoSearchOutline } from 'react-icons/io5';
import ContentEditable from 'react-contenteditable'
import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';
import { Calendar } from 'primereact/calendar';
import { useLocation, useNavigate } from 'react-router-dom';
import { onCreatePublishTask, onFetchAttachment, onFetchCategory, onFetchLevel, onFetchMedia, onFetchTaskData, onFetchTaskType, onUpdateTaskData } from '../../../Redux/Actions/TaskCreatorAction';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { FaSpinner } from "react-icons/fa";
import { RiEdit2Line } from "react-icons/ri";
import DashboardFooter from '../../Common/DashboardFooter';
import { Dropdown } from 'primereact/dropdown';
import SummerNote from './SummerNote';
import CreateUpload from './CreateUpload';
import { FaRegFilePdf } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { MdOutlineVideoLibrary } from "react-icons/md";
const CreateNewTask = () => {
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const slugId = location?.state?.id?.slug ? true : false
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };
    const [isActive2, setIsActive2] = useState(false);
    const toggleNotification = () => {
        setIsActive2(!isActive2);
    };
    const task = location?.state?.id
    const [loader, setLoader] = useState({
        create: false,
        fetch: true
    })
    const [editing, setEditing] = useState(
        location?.state?.id?.slug ? false : true
    )
    const [categories, setCategories] = useState([])
    const [experience, setExperience] = useState([])
    const [socialMedia, setSocialMedia] = useState([])
    const [taskType, setTaskType] = useState([])
    const [attachment, setAttachment] = useState([])

    const [data, setData] = useState({
        category: "",
        level: "",
        title: "",
        description: "",
        price: "1",
        // type: "unlimited",
        due_date: "",
        task_status: "",
        social_media: "",
        bonus: "",
        is_feature: "",
        assets_name: [],
        assets_type: [],
        type: "",
        max_submission: "100",
        assets: [],
        caption: "",
        url: "",
        hashtag: "",

    });

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();

    // Calculate the previous month and year
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = month === 0 ? year - 1 : year;

    // Calculate the next month and year
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = month === 11 ? year + 1 : year;

    // Set minDate to the start of the previous month
    let minDate = new Date(prevYear, prevMonth, 1);


    const handleChange = (e, name) => {

        if (name) {
            const { innerText } = e.currentTarget;
            setData({ ...data, [name]: innerText });

        } else if (e.target.id === "is_feature") {
            const { name, checked } = e.target;
            console.log(name, checked)
            setData({ ...data, [name]: checked === true ? "1" : "0" })
        } else {
            const { value, name } = e.target;
            setData({ ...data, [name]: value });
        }
    };
    const handleSubmissionClick = (type) => {
        setData({ ...data, type: type });
    };

    const handleEditClick = () => {
        setEditing((prev) => !prev)
    }

    const prepareFormData = () => {
        const formData = new FormData();


        if (data.assets && data.assets.length > 0) {
            for (let i = 0; i < data.assets.length; i++) {
                if (data.assets[i]) {
                    formData.append(`assets[${i}]`, data.assets[i]);
                }
            }
        }
        if (data.attachment && data.attachment.length > 0) {
            for (let i = 0; i < data.attachment.length; i++) {
                if (data.attachment[i]) {
                    formData.append(`attachment[${i}]`, data.attachment[i]);
                }
            }
        }
        Object.keys(data).forEach(key => {
            if (key !== 'assets' || key !== 'attachment') {
                formData.append(key, data[key]);
            }
        });


        for (const [key, value] of formData.entries()) {
            // console.log(key, value);
        }

        return formData;
    };

    const handleSubmit = (e) => {
        e.preventDefault();


        const formData = prepareFormData();
        // if (!data.caption) {
        //     dispatch(setAlert("Caption is required.", "danger"))
        //     return;
        // }
        // if (!data.hashtag) {
        //     dispatch(setAlert("Hashtag is required.", "danger"))
        //     return;
        // }
        // if (!urlRegex.test(data.url)) {
        //     dispatch(setAlert("The provided URL is invalid. Please check and try again.", "danger"))
        //     return;
        // }
        if (!data.title) {
            dispatch(setAlert("Task title is required.", "danger"))
            return;
        }
        if (!data.description) {
            dispatch(setAlert("Task description is required.", "danger"))
            return;
        }

        if (!data.due_date) {
            dispatch(setAlert("Due date is required.", "danger"))
            return;
        }

        setLoader({
            ...loader,
            create: true
        })
        if (location?.state?.id?.slug) {

            dispatch(onUpdateTaskData(formData, navigation, loader, setLoader))
        } else {
            dispatch(onCreatePublishTask(formData, navigation, loader, setLoader))
        }
    }
    const fetchCategory = () => {
        dispatch(onFetchCategory(setCategories, loader, setLoader))
    }
    const fetchExperience = () => {
        dispatch(onFetchLevel(setExperience, loader, setLoader))
    }
    const fetchMedia = () => {
        dispatch(onFetchMedia(setSocialMedia, loader, setLoader))
    }
    const fetchTaskType = () => {
        dispatch(onFetchTaskType(setTaskType, loader, setLoader))
    }
    const fetchAttachment = () => {
        dispatch(onFetchAttachment(setAttachment, loader, setLoader))
    }

    const fetchTaskData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let data1 = {
            slug: location?.state?.id?.slug
        }
        dispatch(onFetchTaskData(data1, setData, loader, setLoader))
    }


    const option = categories?.map((curElem, i) => {
        return {
            name: curElem.name,
            value: curElem.id
        };
    });

    const option2 = experience?.map((curElem, i) => {
        return {
            name: curElem.name,
            value: curElem.id
        };
    })
    const option4 = socialMedia?.map((curElem, i) => {
        return {
            name: curElem.name,
            value: curElem.id
        };
    })


    const filteredTaskTypes = () => {
        if (data?.category === '5') {

            return taskType?.filter(task => task?.id !== '6');
        }
        else {
            return taskType?.filter(task => task?.id === '6');
        }

    };
    const option5 = filteredTaskTypes()?.map((curElem, i) => {
        return {
            name: curElem.name,
            value: curElem.id
        };
    })

    const option6 = attachment?.map((curElem, i) => {
        return {
            name: curElem.name,
            value: curElem.id
        };
    })
    const option3 = [{ name: "Open", value: "0" }, { name: "Closed", value: "1" }]


    const formatDateTime = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };
    const handleRemove = (curElemId, index) => {

        const updatedData = { ...data };
        if (index >= 0 && index < updatedData.attachment.length) {

            updatedData.attachment.splice(index, 1);
        }


        updatedData.old_assets.push(curElemId);


        setData(updatedData);
    }
    const handleViewFile = (curElem) => {

        if (curElem.url) {
            const a = document.createElement('a');
            a.href = curElem.url;
            a.target = '_blank';
            a.click();

        }
    }
    useEffect(() => {
        fetchCategory()
        fetchExperience()
        fetchMedia()
        fetchTaskType()
        fetchAttachment()
    }, [])

    useEffect(() => {
        if (location?.state?.id?.slug) {
            // fetchTaskData()
            setData({
                ...task,
                assets: [],
                assets_name: [],
                assets_type: [],
                old_assets: []
            })
        }
    }, [location?.state?.id?.slug])
    const handleUrlChange = (e) => {
        const newValue = e.target.value;
        const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:[0-9]{1,5})?(\/.*)?$/;

        if (!urlRegex.test(newValue)) {
            dispatch(setAlert("The provided URL is invalid.", "danger"))

        }


        handleChange(e, "url");
    };

    // console.log(data)
    return (
        <>
            {loader.fetch ? <div className='d-flex align-items-center justify-content-center' style={{ height: "100vh" }}><FaSpinner className='spin ms-1' style={{ fontSize: "40px" }} /></div>
                :
                <div className="dashboard creator_new_task container-fluid overflow-hidden create-task-footer">

                    <div className="row">
                        {
                            location.state?.id?.slug ? "" :

                                <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                                    <SidePanelTaskCreator Type="url" />
                                </div>
                        }
                        <div className={location.state?.id?.slug ? "col-lg-12 col-md-12 col-sm-8 col-12 p-0 " : ` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                            <div className="row">
                                <div className="col-12 ps-0">
                                    <div className="dashboard-in">
                                        {
                                            location.state?.id?.slug ? "" :

                                                <DashboardHeader title="Create new task" toggleSidebar={toggleClass} />
                                        }


                                        <div className="new_task_head" style={{padding:"15px 30px"}}>
                                            <h3>{location.state?.id?.slug ? "" : "Create New Task"}</h3>
                                        </div>
                                        <ScrollPanel className="custombar2 content-area pt-2">

                                            <form onSubmit={handleSubmit}>
                                                <div className='container-fluid'>
                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            <div className='account-block ms-3 me-3 '>
                                                                <div className="d-flex justify-content-between">
                                                                    <h3>Task Type</h3>
                                                                    {
                                                                        location.state?.id?.slug ? (
                                                                            <span style={{ cursor: "pointer" }} onClick={handleEditClick}>
                                                                                <RiEdit2Line /> Edit Details
                                                                            </span>
                                                                        ) : ""
                                                                    }

                                                                </div>


                                                                <div className="col-12">

                                                                    <div className="submission_wrap mt-3 py-4 ">
                                                                        <span className='mb-2'>Task Category</span>
                                                                        <div style={{ marginLeft: "-10px" }}>
                                                                            <Dropdown
                                                                                value={data.category}
                                                                                onChange={(e) => handleChange(e)}
                                                                                options={option}
                                                                                optionLabel="name"
                                                                                placeholder="Select Type"
                                                                                className="input ms-2 p-2 rounded-3 border-0"
                                                                                name="category"
                                                                                disabled={slugId}
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-lg-6'>
                                                                            <div className="submission_wrap  mt-3">
                                                                                <span className='mb-2'>Task Type
                                                                                </span>
                                                                                <div style={{ marginLeft: "-10px" }}>
                                                                                    <Dropdown
                                                                                        value={data.type}
                                                                                        onChange={(e) => handleChange(e)}
                                                                                        options={option5}
                                                                                        optionLabel="name"
                                                                                        placeholder="Select Type"
                                                                                        className="input ms-2 p-2 rounded-3 border-0"
                                                                                        name="type"
                                                                                        disabled={slugId}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {data.category === "5" ?
                                                                            <div className='col-lg-6'>
                                                                                <div className="submission_wrap  mt-3">
                                                                                    <span className='mb-2'>Social Media Type
                                                                                    </span>
                                                                                    <div style={{ marginLeft: "-10px" }}>
                                                                                        <Dropdown
                                                                                            value={data.social_media}
                                                                                            onChange={(e) => handleChange(e)}
                                                                                            options={option4}
                                                                                            optionLabel="name"
                                                                                            placeholder="Select Type"
                                                                                            className="input ms-2 p-2 rounded-3 border-0"
                                                                                            name="social_media"
                                                                                            disabled={slugId}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : ""}
                                                                    </div>
                                                                    <div className="submission_wrap submission_bg mt-3">
                                                                        <span>Task Title</span>

                                                                        <ContentEditable
                                                                            html={data.title}
                                                                            disabled={!editing}
                                                                            onChange={(e) => handleChange(e, 'title')}
                                                                            tagName="h3"
                                                                            className="mt-2"
                                                                            style={{ fontWeight: "500" }}
                                                                        />

                                                                    </div>
                                                                    <div className="submission_wrap submission_bg mt-3 pt-2">
                                                                        <span className='pb-2'>Task Description</span>
                                                                        <SummerNote state={data} setState={setData} editing={editing} />
                                                                    </div>
                                                                    {
                                                                        data.type === "5" || data.type === "3" ?
                                                                            <>
                                                                                <div className="submission_wrap submission_bg mt-3">
                                                                                    <span>Caption</span>

                                                                                    <ContentEditable
                                                                                        html={data.caption}

                                                                                        onChange={(e) => handleChange(e, "caption")}
                                                                                        tagName="h3"
                                                                                        className="mt-2"
                                                                                        style={{ fontWeight: "500" }}
                                                                                        disabled={slugId}
                                                                                    />

                                                                                </div>
                                                                                <div className="submission_wrap submission_bg mt-3">
                                                                                    <span>Hash tag</span>

                                                                                    <ContentEditable
                                                                                        html={data.hashtag}

                                                                                        onChange={(e) => handleChange(e, "hashtag")}
                                                                                        tagName="h3"
                                                                                        className="mt-2"
                                                                                        style={{ fontWeight: "500" }}
                                                                                        disabled={slugId}
                                                                                    />

                                                                                </div>
                                                                            </>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        data.type === "1" || data.type === "2" || data.type === "4" ? <>
                                                                            <div className="submission_wrap submission_bg mt-3">
                                                                                <span>Post Url</span>

                                                                                <ContentEditable
                                                                                    html={data.url}
                                                                                    disabled={slugId}
                                                                                    onChange={handleUrlChange}
                                                                                    tagName="h3"
                                                                                    className="mt-2"
                                                                                    style={{ fontWeight: "500" }}
                                                                                />

                                                                            </div>
                                                                        </> : ""
                                                                    }

                                                                    <div className="submission_wrap mt-3 py-4 ">
                                                                        <span className='mb-2'>Experience Required</span>
                                                                        <div style={{ marginLeft: "-10px" }}>
                                                                            <Dropdown
                                                                                value={data.level}
                                                                                onChange={(e) => handleChange(e)}
                                                                                options={option2}
                                                                                optionLabel="name"
                                                                                placeholder="Select Type"
                                                                                className="input ms-2 p-2 rounded-3 border-0"
                                                                                name="level"
                                                                                disabled={slugId}
                                                                            />

                                                                        </div>
                                                                    </div>


                                                                    <div className='row'>
                                                                        <div className='col-lg-6'>
                                                                            <div className='submission_wrap mt-3 py-4'>
                                                                                <div className="task-reference-block pt-0">
                                                                                    <span className="custom-check">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="is_feature"
                                                                                            name="is_feature"

                                                                                            checked={data.is_feature === "1" ? true : false}

                                                                                            onChange={(e) => handleChange(e)}
                                                                                            disabled={slugId}

                                                                                        />
                                                                                        <span className="checkMark"></span>
                                                                                    </span>
                                                                                    <div className="task-reference-info">
                                                                                        <label className="form-check-label" for="is_feature">
                                                                                            Feature Task
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {data.is_feature === "1" ?
                                                                            <div className='col-lg-6'>
                                                                                <div className="submission_wrap submission_bg mt-3" style={{ minHeight: "100px" }}>
                                                                                    <span className='mb-2'>Bonus Points
                                                                                    </span>
                                                                                    <div style={{ marginLeft: "0px" }}>
                                                                                        <input
                                                                                            type="number"
                                                                                            name="bonus"
                                                                                            value={data.bonus}
                                                                                            placeholder='Enter Bonus Points'
                                                                                            disabled={slugId}
                                                                                            onChange={handleChange}
                                                                                            onKeyDown={(e) => {
                                                                                                if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                                                                                    e.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            style={{ border: "none", fontWeight: "500", width: "100%" }}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : ""}



                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-lg-6'>
                                                                            <div className="submission_wrap submission_bg mt-3" style={{ minHeight: "100px" }}>
                                                                                <span className='mb-2'>No of submission
                                                                                </span>
                                                                                <div style={{ marginLeft: "0px" }}>
                                                                                    <input
                                                                                        type="number"
                                                                                        name="max_submission"
                                                                                        value={data.max_submission}
                                                                                        placeholder='No of submission'
                                                                                        disabled={slugId}
                                                                                        onChange={handleChange}
                                                                                        onKeyDown={(e) => {
                                                                                            if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        style={{ border: "none", fontWeight: "500", width: "100%" }}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <div className="submission_wrap submission_bg mt-3  ">
                                                                                <span className='mb-2'>₹ Price ( Per submission )</span>
                                                                                <input
                                                                                    type="number"
                                                                                    name="price"
                                                                                    value={data.price}
                                                                                    placeholder='Enter price'
                                                                                    disabled={slugId}
                                                                                    onChange={handleChange}
                                                                                    onKeyDown={(e) => {
                                                                                        if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    style={{ border: "none", fontWeight: "500", width: "100%" }}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className='row'>
                                                                        <div className='col-lg-6'>
                                                                            <div className="submission_wrap submission_bg mt-3 position-relative" style={{ minHeight: "115px" }}>
                                                                                <span>Due Date</span>
                                                                                <div className="flex-aut pt-2">
                                                                                    <div>
                                                                                        <Calendar
                                                                                            id="buttondisplay"
                                                                                            value={data.due_date ? new Date(data.due_date) : null}
                                                                                            onChange={(e) => setData({ ...data, due_date: formatDateTime(e.value) })}
                                                                                            disabled={!editing}
                                                                                            showIcon
                                                                                            dateFormat="yy/mm/dd"
                                                                                            panelClassName="fixed-calendar"
                                                                                            minDate={today}
                                                                                            placeholder='Select date'
                                                                                        />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <div className="submission_wrap  mt-3 py-4 mb-3">
                                                                                <span className='mb-2'>Task Status</span>
                                                                                <div style={{ marginLeft: "-10px" }}>
                                                                                    <Dropdown
                                                                                        value={data.task_status}
                                                                                        onChange={(e) => handleChange(e)}
                                                                                        options={option3}
                                                                                        optionLabel="name"
                                                                                        placeholder="Select Type"
                                                                                        className="input ms-2 p-2 rounded-3 border-0"
                                                                                        name="task_status"
                                                                                        disabled={!editing}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        location?.state?.id?.slug && data?.attachment?.length > 0 ?
                                                                            <div className='submission_wrap p-3 icon-boxes-main d-flex flex-row justify-content-start gap-3'>
                                                                                {
                                                                                    data?.attachment?.length > 0 ? data?.attachment?.map((curElem, index) => {


                                                                                        return (
                                                                                            <div className='account-block mb-0 icon-boxes' key={index}>
                                                                                                <span className='icon-box-cross'>
                                                                                                    <MdCancel style={{ color: "#afa4a8" }} onClick={() => handleRemove(curElem?.id, index)} />
                                                                                                </span>
                                                                                                <span onClick={() => handleViewFile(curElem)}>
                                                                                                    {curElem.attachmenttype === "PDF" ? <FaRegFilePdf style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} /> : curElem.attachmenttype === "Image" ? <MdOutlineAddPhotoAlternate
                                                                                                        style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} /> : <MdOutlineVideoLibrary
                                                                                                        style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} />}
                                                                                                </span>
                                                                                                <p>{curElem.name}</p>
                                                                                            </div>
                                                                                        )
                                                                                    }) : ""
                                                                                }



                                                                            </div> : ""}

                                                                    <CreateUpload
                                                                        editing={editing}
                                                                        data={data}
                                                                        setData={setData}
                                                                        handleChange={handleChange}
                                                                        option6={option6}
                                                                    />
                                                                    <div className="new_task_create ">


                                                                        <button className='theme-btn' type='submit' disabled={!editing} style={!editing ? { color: "#fff", background: "gray" } : {}}>
                                                                            {loader.create ? (
                                                                                <>{location.state?.id?.slug ? "Updating Task" : "Publishing Task "} <FaSpinner className="spin" size={15} /></>
                                                                            ) : (
                                                                                <>
                                                                                    {location.state?.id?.slug ? "Update Task" : "Create Task "}
                                                                                </>
                                                                            )}
                                                                        </button>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </ScrollPanel>
                                        <DashboardFooter />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    )
}

export default CreateNewTask
