import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../../Redux/Actions/AlertActions'
import { FaSpinner } from 'react-icons/fa'
import { socialAction } from '../../../Redux/Actions/AuthActions'

const SocialLink = () => {
    const [social, setSocial] = useState("")
    const dispatch = useDispatch()
    const [type, setType] = useState("")
    const [loader, setLoader] = useState(false)
    const handleChange = (e, type) => {
        console.log(type)
        setSocial(e.target.value)
        setType(type)
        // console.log(e.target.value)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const urlRegex = /^(https?:\/\/|ftp:\/\/|www\.)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/;
        if (!urlRegex.test(social.trim())) {
            setLoader(false)
            setSocial("")
            setType("")
            dispatch(setAlert(`The provided ${type} URL is invalid.`, "danger"))
            return
        }
        let data = {

            "type": type,
            "url": social

        }
        dispatch(socialAction(data, setLoader, setSocial, setType))



    }

    return (
        <div className='creator_new_task h-100'>
            <div className='account-block phone-emails mb-5'>
                <form onSubmit={handleSubmit}>
                    <div className="submission_wrap submission_bg mt-3 mb-4" style={{ height: "115px" }}>
                        <label htmlForfor="" className="form-label fs-5 fw-semibold">Facebook</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="http://example.com" name="facebook" value={type === "facebook" ? social : ""} onChange={(e) => handleChange(e, "facebook")} />
                    </div>
                    <div class="modal-button-bottom mb-4" style={{ background: "unset" }}><button class="theme-btn px-4" type="submit"  >Update{type === "facebook" && loader ? <FaSpinner className="spin ms-1" /> : null} </button></div>
                </form>
                <form onSubmit={handleSubmit}>
                    <div className="submission_wrap submission_bg mt-3 mb-4" style={{ height: "115px" }}>
                        <label htmlForfor="" className="form-label fs-5 fw-semibold">YouTube</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="http://example.com" name="youtube" value={type === "youtube" ? social : ""} onChange={(e) => handleChange(e, "youtube")} />
                    </div>
                    <div class="modal-button-bottom mb-4" style={{ background: "unset" }}><button className="theme-btn px-4" type="submit"  >Update {type === "youtube" && loader ? <FaSpinner className="spin ms-1" /> : null} </button></div>
                </form>
                <form onSubmit={handleSubmit}>
                    <div className="submission_wrap submission_bg mt-3 mb-4" style={{ height: "115px" }}>
                        <label htmlForfor="" className="form-label fs-5 fw-semibold">Twitter</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="http://example.com" name="twitter" value={type === "twitter" ? social : ""} onChange={(e) => handleChange(e, "twitter")} />
                    </div>
                    <div class="modal-button-bottom mb-4" style={{ background: "unset" }}><button className="theme-btn px-4" type="submit"  >Update {type === "twitter" && loader ? <FaSpinner className="spin ms-1" /> : null} </button></div>
                </form>
                <form onSubmit={handleSubmit}>
                    <div className="submission_wrap submission_bg mt-3 mb-4" style={{ height: "115px" }}>
                        <label htmlForfor="" className="form-label fs-5 fw-semibold">Instagram</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="http://example.com" name="instagram" value={type === "instagram" ? social : ""} onChange={(e) => handleChange(e, "instagram")} />
                    </div>
                    <div>
                        <div class="modal-button-bottom mt-3" style={{ background: "unset" }}><button className="theme-btn px-4" type="submit"  >Update {type === "instagram" && loader ? <FaSpinner className="spin ms-1" /> : null} </button></div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SocialLink
