import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect } from 'react'
import frame from '../../../images/icon-2/Frame.svg'
import { PiUserCircle } from "react-icons/pi";
import { IoInformationCircleOutline, IoLocationOutline } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { Form } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import AdminCreatorNav from './AdminTaskDeatils/AdminCreatorNav';
import DashboardFooter from '../../Common/DashboardFooter';
const AdminCreatorProfile = (props) => {

    return (
        <>
            {props.loader.fetch ? <div className='d-flex align-items-center justify-content-center'><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
                <>
                    <ScrollPanel className="custombar2 manage-users content-area pt-2">
                        <div className="main_task_details ps-4 pe-4 pb-4">
                            <AdminCreatorNav data={props?.data} />
                            <div className="account-block  mt-4">
                                <h6>Profile Details</h6>

                                <div className='admin_profile_details '>
                                    <div class="mb-3 row">
                                        <label for="staticEmail" className="col-lg-2 col-md-4 col-12  col-form-label">Name</label>
                                        <div class="col-lg-10 col-md-6 col-12">
                                            <p>{props.data?.name}</p>
                                        </div>
                                    </div>
                                    <div class="mb-3 row">
                                        <span for="staticEmail" className="col-lg-2 col-md-4 col-12 col-form-label ">Contact Phone <span className='tooltipN'><IoInformationCircleOutline /><span className='tooltiptext'>Contact Phone</span></span></span>
                                        <div class="col-lg-10 col-md-6 col-12">
                                            <p>{props.data?.mobileNumber} <span class="badge ">Verified</span></p>
                                        </div>
                                    </div>
                                    <div class="mb-3 row">
                                        <label for="staticEmail" className="col-lg-2 col-md-4 col-12 col-form-label">Email</label>
                                        <div class="col-lg-10 col-md-6 col-12 ">
                                            <p>{props.data?.email}<span class="badge ">Verified</span></p>
                                        </div>
                                    </div>

                                    <div class="mb-3 row">
                                        {/* <label for="staticEmail" className="col-lg-2 col-md-4 col-12 col-form-label">Language</label> */}
                                        {/* <div class="col-lg-10 col-md-6 col-12">
                                        {props?.data?.language_name.length > 0 ? props?.data?.language_name.map((curElem, i) => {

                                            return (
                                                <span key={i}>{curElem.name},</span>
                                            )
                                        }) : ""}
                                    </div> */}
                                    </div>
                                    <div class="mb-3 row">
                                        <label for="staticEmail" class="col-lg-2 col-md-4 col-12 col-form-label">Age</label>
                                        <div class="col-lg-10 col-md-6 col-12">
                                            <p>{props.data?.age}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="account-block  mt-4">
                                <h6>Notification Settings</h6>
                                <div className="col-12">
                                    <div className="admin_profile_notification">
                                        <h6>Notification</h6>
                                        <div className="admin_notification">
                                            <div>
                                                <p>Receive push notifications on your web app.</p>
                                                <span>Web push notifications</span>
                                            </div>
                                            <div>
                                                <Form>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                    />

                                                </Form>
                                            </div>
                                        </div>
                                        <div className="admin_notification">
                                            <div>
                                                <p>Email notifications</p>
                                                <span>Email notifications</span>
                                            </div>
                                            <div>
                                                <Form>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                    />

                                                </Form>
                                            </div>
                                        </div>
                                        <div className="admin_notification">
                                            <div>
                                                <p>Mobile notifications</p>
                                                <span>Receive updates in your mobile number.</span>
                                            </div>
                                            <div>
                                                <Form>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                    />

                                                </Form>
                                            </div>
                                        </div>
                                        <div className="admin_notification">
                                            <div>
                                                <p>Whatsapp notifications</p>
                                                <span>Receive updates in your whatsapp number.</span>
                                            </div>
                                            <div>
                                                <Form>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                    // label="Check this switch"
                                                    />

                                                </Form>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>


                        </div>
                    </ScrollPanel>
                    <DashboardFooter />
                </>
            }
        </>

    )
}

export default AdminCreatorProfile
