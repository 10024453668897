import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpider, FaSpinner } from "react-icons/fa";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import OtpModelForget from './OtpModelForget';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../Redux/Actions/AuthActions';



function ForgotEmailModal(props) {
    const dispatch=useDispatch()
    const [loader,setLoader]=useState(false)
    const [email,setEmail]=useState('')
    const [show, setShow] = useState(false);
const[session_id,setSession_id]=useState('')
const handleShow = () => setShow(true);
const handleClose = () => setShow(false);



   
    const handleSubmit=(e)=>{
        setLoader(true)
        e.preventDefault()
        let formData={
            "email":email
        }
       
    dispatch(resetPassword(formData,setLoader, props.handleClose,setSession_id,handleShow))
        
    }
    return (
        <>
            <Modal className="theme-modal forgotpass-moda creator_new_task link_submit_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                
                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-3'>
                    <div className='d-flex align-items-center modal-btn justify-content-between'>
                   {/* <span><MdOutlineArrowBackIos /></span>  */}
                    <h3 className="m-auto"style={{fontSize:"25px"}}>Enter your Email </h3>
                 {/* <span onClick={props.handleClose}><RxCross1 /></span>    */}

                    </div>
               <form onSubmit={handleSubmit}>
                    <div className="modal-card mt-4 ">
                     <div className='account-block mb-0'>
           <div className='submission_wrap submission_bg mt-3 mb-3'>
           <div className='link_checkbox d-flex flex-column'>
           <label htmlFor="" class="form-label mb-0">Email</label>
                        <input style={{padding:"8px"}}
                          className="border-0 w-100"
                          type="email"
                          placeholder="Enter your email address "
                          value={email}
                          onChange={(e)=>setEmail(e.target.value)}
                          name="email"
                          required
                        />
               
                        </div>
           </div>
                     </div>
                        <div className="link_btn text-end ">
                            <button className='theme-btn saveBtn ms-auto me-3 d-flex' type="submit" style={{padding:"8px 24px"}}>Submit {loader ? <FaSpinner className="spin ms-1" /> : null}</button>

                        </div>
                    </div>
                    </form>
                </Modal.Body>
            </Modal >
            <OtpModelForget show={show} handleClose={handleClose} email={email} session_id={session_id}/>
        </>
    )
}

export default ForgotEmailModal