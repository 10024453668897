// import React, { useState } from "react";

import axios from "axios";
import { baseURL } from "../../../Global/Global";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import { error } from "pdf-lib";
import logo from '../../../images/logo.svg'

const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};
export const displayRazorpay = async (data, paymentkey, token, navigation) => {

    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load .Are you online?");
    } else {

        const options = {
            key: "rzp_test_fWDFminqLPkRy8",
            amount: data?.totalamount * 100,
            currency: "INR",
            name: "DoTasks",
            image: "https://reeelapps-app.s3.us-west-2.amazonaws.com/DoTasks/Dologo.png",

            order_id: data.orderId,
            description: "Task Payment Transaction",
            handler: async (response) => {
                await axios({
                    method: "POST",
                    url: `${baseURL}/payment-success`,
                    data: JSON.stringify({
                        "paymentkey": paymentkey,
                        "orderId": response?.razorpay_order_id,
                        "paymentId": response?.razorpay_payment_id,
                        "signature": response?.razorpay_signature
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    }
                }).then(res => {
                    console.log(res)
                    if (res.data.status) {
                        navigation("/creator/task")
                        setAlert(res.data.message, "success")

                    }

                }).catch(err => {
                    console.log(err)

                })




            },
            prefill: {
                name: data?.name,
                email: data?.email,
                contact: data?.mobileNumber,
            },
            theme: {
                color: "#f10065 ",

            },
        };

        const rzp1 = new window.Razorpay(options);
        console.log(rzp1);
        rzp1.open();
        rzp1.on('payment.failed', async (response) => {
            console.log(response)
            await axios({
                method: "POST",
                url: `${baseURL}/payment-failure`,
                data: JSON.stringify({
                    "paymentkey": paymentkey,
                    "orderId": response?.error.metadata.order_id,
                    "paymentId": response?.error.metadata.payment_id,
                    "description": response?.error.description,
                    "errorcode": response?.error.code,
                    "reason": response?.error.reason,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(res => {

                if (res.status) {

                    setAlert(res.message, "danger")
                    navigation("/creator/task")
                }

            }).catch(err => {
                console.log(err)

            })




        },
        );
    }
};