import React from "react";
import { Link, NavLink } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { HiOutlineChevronRight } from "react-icons/hi2";
import { PiCertificate, PiNotebookBold } from "react-icons/pi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";

const SidePanelTaskCreator = ({ Type }) => {

    return (
        <>
            <div className="side-panel">
                <div className="logo position-relative"><Link to="/creator/dashboard"> <div><img src={require("../../images/logo.svg").default} alt="" /></div></Link><span className="page-name">Creator</span></div>
                {/* <div className="fav-tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Nav variant="nav">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Favorites</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Recently</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <ul className="tab-link">
                                    <li><Link>Overview</Link></li>
                                    <li><Link>Projects</Link></li>
                                </ul>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <ul className="tab-link">
                                    <li><Link>Overview</Link></li>
                                    <li><Link>Projects</Link></li>
                                </ul>
                            </Tab.Pane>

                        </Tab.Content>
                    </Tab.Container>
                </div> */}

                <div className="dash-link pt-0">
                    {/* <h6>Dashboard</h6> */}
                    <ul>
                        <li><NavLink to="/creator/dashboard"><HiOutlineChevronRight /> <img src={require("../../images/icon/agent.svg").default} alt="" /> Dashboard</NavLink></li>
                        <li><NavLink to="/creator/task" className={Type === "url" ? "active" : ""}><HiOutlineChevronRight /> <PiNotebookBold fontSize={20} /> Tasks</NavLink></li>
                        <li><NavLink to="/task-creator/reviews"><HiOutlineChevronRight /> <PiCertificate fontSize={20} /> Reviews</NavLink></li>
                        {/* <li><NavLink to="/task-creator/spending"><HiOutlineChevronRight /> <RiMoneyDollarCircleLine fontSize={20} /> Spending</NavLink></li> */}
                        {/* <li><NavLink to="/task-creator/profile-settings"><HiOutlineChevronRight /> <img src={require("../../images/icon/IconSet.svg").default} alt="" /> Settings</NavLink></li> */}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SidePanelTaskCreator;