import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap';
import PayoutHistory from './PayoutHistory';
import EarningsPayouts from './EarningsPayouts';
import { useDispatch } from 'react-redux';
import { fetchEarningsData } from '../../../Redux/Actions/EarningsAction';


const NavTabEarning = () => {
    const dispatch=useDispatch()
    const[data,setData]=useState([])
    const[loader,setLoader]=useState(
       { fetch:true,
       }
    )
    const fetchData=()=>{
      dispatch(fetchEarningsData(setData,setLoader,loader))
    }

    useEffect(()=>{  
        fetchData()
    },[])
   
   
    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="row" >
                    <div className="col-12">
                        <Nav variant="pills" style={{ padding: "10px 28px 15px 6px" }} >
                            <Nav.Item>
                                <Nav.Link eventKey="first" > My Earnings</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Payout History</Nav.Link>
                            </Nav.Item>
                        </Nav>

                    </div>

                    <div className="col-12">
                        <div className="setting_right_side_wrap">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <EarningsPayouts data={data.myearning} loader={loader} earningslist={data.earningslist}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <PayoutHistory data={data.history} loader={loader}/>
                                </Tab.Pane>


                            </Tab.Content>
                        </div>
                    </div>
                </div>
            </Tab.Container >
        </>
    )
}

export default NavTabEarning
