import React, { useState } from 'react';

import SidePanel from "../../Common/SidePanel";
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import NotificationBar from "../../Common/NotificationBar";
import { ScrollPanel } from 'primereact/scrollpanel';

import PersonalDetailsTabs from "./PersonalDetailsTabs";
import QualificationExperienceTabs from "./QualificationExperienceTabs";
import TaskPreferenceTabs from "./TaskPreferenceTabs";
import PaymentMethodsTabs from "./PaymentMethodsTabs";
import NotificationTabs from "./NotificationTabs";

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import TitleBar from '../../Common/TitleBar';
import EmailPhoneTasker from './EmailPhoneTasker';
import PasswordTasker from './PasswordTasker';
import TaskerKyc from './TaskerKyc';
import SecurityTasker from './SecurityTasker';
import SocialLink from './SocialLink';

function ProfileSettings() {

   const [isActive, setIsActive] = useState(true);
   const toggleClass = () => {
      setIsActive(!isActive);
   };

   // const [isActive2, setIsActive2] = useState(false);
   // const toggleNotification = () => {
   //    setIsActive2(!isActive2);
   // };

   return (
   <>
            <TitleBar title="Dashboard" />
            <div className="dashboard container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanel />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 ps-0 pe-0">
                                    <div className="dashboard-in">
                                        <DashboardHeader toggleSidebar={toggleClass} title="Dashboard" />
                                        <ScrollPanel className="custombar2 content-area">
                  <div className="content-area-in tab-style">
                     <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                        <div className="tab-bar">
                           <Nav variant="pillsb">
                              <Nav.Item><Nav.Link eventKey="tab-1">Personal Details</Nav.Link></Nav.Item>
                              {/* <Nav.Item><Nav.Link eventKey="tab-2">Qualification & Experience</Nav.Link></Nav.Item> */}
                              <Nav.Item><Nav.Link eventKey="tab-3">Task Preference</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-4">Payment Methods</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-5">Notifications</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-6">Email & Phone Number</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-10">Social</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-7">Password</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-8">KYC</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-9">Security</Nav.Link></Nav.Item>

                           </Nav>

                           {/* <div className="tab-bar-right">
                     <ul>
                        <li><Link>+ Add User</Link></li>
                        <li><Link>Add Target</Link></li>
                        <li>
                           <span className="profile-menu">
                              <Dropdown>
                                 <Dropdown.Toggle variant="" id="dropdown-basic">
                                    <BiDotsHorizontalRounded />
                                 </Dropdown.Toggle>
                                 <Dropdown.Menu>
                                    <Link>Action</Link>
                                    <Link>Another action</Link>
                                    <Link>Something else</Link>
                                 </Dropdown.Menu>
                              </Dropdown>
                           </span>
                        </li>
                     </ul>
                  </div> */}

                        </div>
                        <Tab.Content>
                           <Tab.Pane eventKey="tab-1">
                              <PersonalDetailsTabs />
                           </Tab.Pane>
                           {/* <Tab.Pane eventKey="tab-2">
                              <QualificationExperienceTabs />
                           </Tab.Pane> */}
                           <Tab.Pane eventKey="tab-3">
                              <TaskPreferenceTabs />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-4">
                              <PaymentMethodsTabs />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-5">
                              <NotificationTabs />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-6">
                              <EmailPhoneTasker />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-7">
                              <PasswordTasker />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-8">
                              <TaskerKyc />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-9">
                              <SecurityTasker />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-10">
                              <SocialLink />
                           </Tab.Pane>
                        </Tab.Content>
                     </Tab.Container>
                  </div>
               </ScrollPanel>
                                        <div className="d-none d-lg-block">
                                            <DashboardFooter />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 ps-0">
                                    <RecommendTasks />
                                </div> */}
                                <div className="d-block d-lg-none">
                                    <DashboardFooter />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>




        </>
   )
}
export default ProfileSettings;